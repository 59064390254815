<template>
	<div class="home qjc-theme-bg qjc-c-thin">
		<van-nav-bar
			title="首頁"
			fixed
			:z-index="9999"
			class="qjc-nav-bar"
		/>
		
		<van-image
			width="100%"
			height="auto"
			:src="require('@/assets/images/home_title.png')"
		/>
		
		<div class="main qjc-margin-ct">
			<div class="medinHk">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					香港體檢攜手米因健康大數據
				</h3>
				<div class="border-radius qjc-bg-fff">
					<div>
						<van-image
							width="2.56rem"
							height="0.76rem"
							:src="require('@/assets/images/miyinlogo.png')"
						/>
						<van-image
							width="2.56rem"
							height="0.76rem"
							:src="require('@/assets/images/HKCKlogo.png')"
						/>
					</div>
					<div class="medinHk-des qjc-texta-l">
						<p>推出個人化的健康和醫療大數據，AI對檢驗結果的詳細分析和預測功能。</p>
						<br>
						<p>客戶是透過輸入個人基本資料、問卷資料、常規血液檢查、常見生化指標的結果，再經AI進行分析，就可以預測到總體健康、體型評估、致病風險、醫療費用、不患病增壽、生化指標評估、同族群對比、健康風險及健康管理和改善方案等等，希望能夠達成「治未病」的目標。</p>
					</div>
				</div>
			</div>
			
			<div class="combo am-hide">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					AI健康套餐
					<!-- AI健康套餐購買 -->
				</h3>
				<p class="qjc-fts-24 qjc-c-fff qjc-ftw-n">（大數據評估項目僅適用於年齡在20~90周歲之間人群）</p>
				<ul>
					<li v-for="item in goodsList" :key="item.goods_id" v-if="item.type == 'ZHJK2'">
						<van-image
							width="100%"
							height="0.98rem"
							:src="require('@/assets/images/aijk_btn.png')"
							@click="handleToQuestion(item.goods_id)"
						/>
					</li>
					<!-- <li v-for="img in image.comboButton" :key="img.package">
						<van-image
							width="100%"
							height="0.98rem"
							:src="img.imgSrc"
							@click="buyCombo(img.package)"
						/>
					</li> -->
				</ul>
			</div>
			<!-- <div class="combo-yjqz">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					疫境求真大數據套餐
					<van-image
						class="new"
						width="0.82rem"
						height="0.36rem"
						:src="require('@/assets/images/index_new.png')"
					/>
				</h3>
				<p class="qjc-fts-24 qjc-c-fff qjc-ftw-n">（套餐適用於20~90歲人群）</p>
				<ul>
					<li>
						<van-image
							width="100%"
							height="0.98rem"
							:src="require('@/assets/images/yjqzbutton.png')"
							@click="buyCombo(4, '/home/buyYjqz')"
						/>
					</li>
				</ul>
			</div> -->
			<div class="case am-hide">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					案例展示
				</h3>
				<div class="box">
					<van-image
						@click="$router.push('/case/case_ai')"
						width="100%"
						:src="require('@/assets/images/aijk_case.png')"
					/>
					<van-image
						@click="$router.push('/case/case_cream')"
						width="100%"
						:src="require('@/assets/images/bljy_case.png')"
					/>
					<van-image
						@click="$router.push('/case/case_baijin')"
						width="100%"
						:src="require('@/assets/images/zgbj_case.png')"
					/>
				</div>
				<!-- <van-image
					@click="$router.push('/home/caseShow/4')"
					width="100%"
					:src="require('@/assets/images/index_case_yjqz.png')"
				/> -->
			</div>
			
			<div class="medin">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					米因健康大數據庫及算法
				</h3>
				<van-grid class="qjc-texta-c" :column-num="3" gutter="0.17rem">
					<van-grid-item>
						<div class="top qjc-wid-100">
							<van-image
								width="0.9rem"
								height="0.88rem"
								:src="require('@/assets/images/xiangshi.png')"
							/>
						</div>
						<dl class="bottom">
							<dt class="qjc-fts-28 qjc-ftw-b qjc-c-dark">詳實</dt>
							<dd class="qjc-texta-l">涵蓋健康、亞健康、醫療需求者人群的醫療健康大數據，生老病死、疾病發展、用藥就診全覆蓋；</dd>
						</dl>
					</van-grid-item>
					<van-grid-item>
						<div class="top qjc-wid-100">
							<van-image
								width="0.9rem"
								height="0.88rem"
								:src="require('@/assets/images/lianxu.png')"
							/>
						</div>
						<dl class="bottom">
							<dt class="qjc-fts-28 qjc-ftw-b qjc-c-dark">連續</dt>
							<dd class="qjc-texta-l">逾2500萬亞洲人，全人口、全世代，持續15-30年的醫療健康數據， 包括醫院、體檢、公共衛生等數據；</dd>
						</dl>
					</van-grid-item>
					<van-grid-item>
						<div class="top qjc-wid-100">
							<van-image
								width="0.9rem"
								height="0.88rem"
								:src="require('@/assets/images/zhunque.png')"
							/>
						</div>
						<dl class="bottom">
							<dt class="qjc-fts-28 qjc-ftw-b qjc-c-dark">準確</dt>
							<dd class="qjc-texta-l">所用大數據算法於千萬級華人醫療健康大數據庫進行回溯驗證，大數據算法敏感度及特異度均高於95%，米因大數據團隊方會採用。</dd>
						</dl>
					</van-grid-item>
				</van-grid>
				<div class="medin-describe border-radius qjc-texta-l qjc-bg-fff">
					<p>個人免疫力是抵禦各種疾病的最堅實可靠的重要防線。當代多種不健康的生活習慣、心理和生理上的重重負擔等因素，導致越來越多的人成為亞健康人群，嚴重者更是會患上各種基礎疾病，例如三高、慢性呼吸疾病、消化系統疾病等。</p>
					<p>亞健康狀態和各種基礎疾病是免疫力低下的體現，平時或許感受不到，一旦面臨突發的大型流行疾病侵襲，問題便會凸顯。免疫力低下的人群，其危重症比例、死亡比例都會比健康人群高出許多。</p>
					<p>如果能夠重視自身的健康狀況，科學分析健康風險，進行有效的健康管理和健康促進，提高自身的免疫力和身體機能，就能更加堅實有效地抵禦各種疾病的侵擾。<span class="qjc-c-primary qjc-ftw-b">米因健康大數據</span>攜手<span class="qjc-c-primary qjc-ftw-b">香港體檢</span>，通過定期的專業身體檢查，結合現代醫療健康大數據科技，幫助大眾分析健康現況，儘早預知健康風險，並通過科學的改善方案，幫助每一個人確實提高自身免疫力，改善健康狀況，成為在與自己同樣性別、年齡、身體條件的同類人群中，跑贏健康大市的“績優牛股”。願天下人人不再患病才求醫，健康幸福觸手可達。</p>
				</div>
			</div>
			<div class="experts">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					專家團隊
				</h3>
				<ul>
					<li v-for="expert in experts" :key='expert.name' class="qjc-bg-fff border-radius qjc-clearfix">
						<van-image
							width="1.28rem"
							height="1.28rem"
							round
							:src="expert.image"
						/>
						<dl class="qjc-texta-l">
							<dt class="qjc-fts-28 qjc-c-dark qjc-ftw-b">{{ expert.name }}</dt>
							<dd>{{ expert.introduce }}</dd>
						</dl>
					</li>
				</ul>
			</div>
			<div class="paper">
				<h3 class="title-xie qjc-c-fff qjc-margin-ct qjc-fts-36">
					<van-image
						width="0.11rem"
						height="0.3rem"
						:src="image.titleXie"
					/>
					學術論文
				</h3>
				<van-image
					width="100%"
					height="4.96rem"
					:src="require('@/assets/images/lunwen.png')"
					@click="lunwen=true"
				/>
				<div class="paper-des qjc-c-fff qjc-margin-ct qjc-texta-l">
					<p>米因醫療健康大數據與上海中醫藥大學、上海曙光醫院聯合發表論文：《不同職場的職工健康體檢數據分析研究</p>
					<p>收錄於核心期刊《解放軍預防醫學雜誌》（2019年7月刊）</p>
				</div>
			</div>
		</div>
		
		<!-- 马上登陆 -->
		<div v-if="!this.$store.state.isLogin" class="to-login qjc-fixed qjc-clearfix">
			<div class="qjc-fl qjc-c-fff qjc-fts-24">
				<van-image
					width="0.6rem"
					height="0.6rem"
					:src="require('@/assets/images/toLogin_user.png')"
				/>
				歡迎來到大數據體檢平臺～
			</div>
			<van-button to="/login" class="qjc-bg-primary qjc-c-fff qjc-fts-28 qjc-fr">馬上登錄</van-button>
		</div>
		
		<!-- 底部导航 -->
		<van-tabbar 
			v-model="navIcon.active"
			active-color="#6681FA"
			inactive-color="#9DA6B9"
			safe-area-inset-bottom
		>
			<van-tabbar-item name="home" to="/">
				<span>首頁</span>
				<img 
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.home.active:navIcon.home.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="study" to="/study">
				<span>米因研究</span>
				<img
					style="width: 0.37rem;height: 0.43rem;"
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.study.active:navIcon.study.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="learn" to="/learn">
				<span>學術中心</span>
				<img
					style="width: 0.37rem;height: 0.43rem;"
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.learn.active:navIcon.learn.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="user" :to="isPerfectInfo ? '/user' : '/regist/perfectInfo'">
				<span>我的</span>
				<img
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.user.active:navIcon.user.normal"
				>
			</van-tabbar-item>
		</van-tabbar>
   
		<!-- 论文预览 -->
		<van-image-preview
			v-model="lunwen"
			:images="lunwenimage"
			:loop="false"
			:show-index="false"
			:show-indicators="false"
			:max-zoom="6"
		>
			
		</van-image-preview>
		
		<!-- 评估未完成弹窗 -->
		<van-popup 
			v-model="complete" 
			:close-on-click-overlay="false"
			class="assess-nodone"
			:class="{ 'yjqz-alert': yjqz }"
		>
			
			<div v-if="yjqz" class="yjqz-box qjc-absolute">
				<van-image
					width="100%"
					height="100%"
					:src="require('@/assets/images/index_yjqz.png')"
				/>
				<van-button
					to="/question"
					round
					class="qjc-bg-ffff qjc-ftw-b qjc-fts-32 qjc-c-primary"
				>
					前往完成
				</van-button>
			</div>
			<div v-else>
				<van-image
					width="3.2rem"
					height="2.55rem"
					:src="require('@/assets/images/errorTip.png')"
				/>
				<p class="qjc-fts-32 qjc-c-dark">您上一次的健康大數據評估尚未完成，是否繼續？</p>
				<van-button
					@click="complete = false"
					round
					class="qjc-c-primary qjc-ftw-b qjc-borderc-primary qjc-fts-32"
				>
					不用了
				</van-button>
				<van-button
					to="/newgoods/question"
					round
					class="qjc-bg-primary qjc-ftw-b qjc-fts-32 qjc-c-fff"
				>
					繼續完成
				</van-button>
			</div>
			
			<!-- 关闭按钮 -->
			<div @click="complete = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
	</div>
</template>

<script>
    import Vue from 'vue'
    import { NavBar, Image, Grid, GridItem, Tabbar, TabbarItem, Button, ImagePreview, Popup, Toast } from 'vant'
    Vue.use(NavBar)
	   .use(Image)
	   .use(Grid)
	   .use(GridItem)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Button)
	   .use(Toast)
	   .use(ImagePreview)
	   .use(Popup);
	 
	 import { isPay, hasToken, packagePrice } from "@/js/axios"
	
    export default {
        name: "home",
        data() {
            return {
				image: {
					titleXie: require('@/assets/images/xie.png'),//小标题前斜杠图
					comboButton: [
						{package: 6,imgSrc: require('@/assets/images/aijk_btn.png')},
						{package: 7,imgSrc: require('@/assets/images/bljy_btn.png')},
						{package: 8,imgSrc: require('@/assets/images/zgbj_btn.png')}
					]//套餐购买列表button图
				},
				experts: [
					{
						name: '黃旭明 教授',
						image: require('@/assets/images/huangxuming.png'),
						introduce: '台灣健保數據庫的奠基人，曾任台北醫學大學、中國醫藥大學副教授，專長於健康大數據的研發與應用。黃旭明教授任台灣地區「衛生署生物統計中心主任」長達14年，從無到有整建了台灣全人口醫療健康大數據庫，涵蓋全世代生老病死的海量時間數列數據，並在此數據基礎上研發出一系列針對華人的健康大數據算法引擎。'
					}
					// ,{
					// 	name: '陸一鳴 教授',
					// 	image: require('@/assets/images/luyiming.png'),
					// 	introduce: '教授、主任醫師、博士生導師，原上海交通大學醫學院附屬瑞金醫院院長助理和急診部主任，現任瑞金醫院北院急診科主任和創傷中心主任、臨床醫學院急救醫學教研室主任，中法生命科學和基因組研究中心主任、上海聯合道路交通安全科學研究中心副理事長、上海馬拉松運動醫學研究所理事長等。'
					// }
				],//专家团队内容列表
				navIcon: {
					active: 'home',
					home: {
						normal: require('@/assets/images/shouye1.png'),
						active: require('@/assets/images/shouye.png')
					},
					study: {
						normal: require('@/assets/images/study.png'),
						active: require('@/assets/images/study_active.png')
					},
					learn: {
						normal: require('@/assets/images/learn.png'),
						active: require('@/assets/images/learn-active.png')
					},
					user: {
						normal: require('@/assets/images/wode.png'),
						active: require('@/assets/images/wode2.png')
					}
				},// 底部导航栏icon
				lunwen: false,//论文预览展示
				lunwenimage: [require('@/assets/images/lunwen2.png')],
				complete: false,//问卷是否完成
				givingMasks: this.$store.state.isLogin,//登录显示
				givingMasksIcon: true,//右下角小图
				
				yjqzShow: !this.$store.state.isLogin,// 疫境求真弹窗显示
				yjqz: false,// 未完成报告是否为疫境求真，
				isPerfectInfo: true, //是否完善信息
				goodsList: [],
			}
        },
		created() {
			// this.getPackageInfo();
		},
		mounted (){
			sessionStorage.removeItem('package');
			// 已登录存在未完成问卷提示
			if(this.$store.state.isLogin){
				let userInfo = JSON.parse(localStorage.getItem('user'))
				if(userInfo && userInfo.birth != '' && userInfo.sex != 0) {
					this.isPerfectInfo = true
				} else {
					this.isPerfectInfo = false
				}
				
			}
		},
		methods: {
			buyCombo (p, path){
				sessionStorage.setItem('package',p);
				this.$router.push(path?path:'/home/buyGoods');
			},
			handleToQuestion(id) {
				sessionStorage.setItem('goods_id', id);
				this.$router.push('/aizh/question');
			},
			getPackageInfo (){//获取套餐信息
				packagePrice().then(res => {
					if(res.status == 1){
						var result = res.data;
						console.log(result)
						this.goodsList = result
					} else {
						Toast.fail(res.msg)
					}
				});
			},
		}
    }
</script>

<style scoped>
	.van-grid >>> .van-grid-item__content--center{
		padding: 0;
	}
	.case >>> .van-grid-item__content{
		background-color: transparent;
	}
	.case >>> .van-grid-item__content::after{
		border: none;
	}
</style>
<style lang="scss" scoped>
	.border-radius{
		border-radius: 0.04rem;
	}
	.home{
		padding-top: 0.88rem;
		padding-bottom: 0.98rem;
	}
	.main{
		width: 7.02rem;
	}
	.title-xie{
		height: 0.36rem;
		line-height: 0.36rem;
		margin-top: 0.64rem;
		margin-bottom: 0.31rem;
		
		.van-image{
			vertical-align: top;
			padding-top: 0.03rem;
		}
	}
	.medin{
		.van-grid{
			width: 7.36rem;
			margin-left: -0.17rem;
		}
		.top{
			height: 1.6rem;
			padding: 0.36rem 0;
		}
		.bottom{
			height: 4.3rem;
			overflow-y: auto;
			
			dt{
				height: 0.86rem;
				padding-top: 0.32rem;
				background:linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(242,244,255,1) 100%);
			}
			
			dd{
				line-height: 0.36rem;
				padding: 0 0.24rem;
			}
		}
		& >>> .van-grid-item__content{
			padding: 0;
			background-color: #fff;
			overflow: hidden;
			border-radius: 0.04rem;
		}
		.medin-describe{
			margin-top: 0.26rem;
			padding: 0.26rem;
			font-size: 0.28rem;
			line-height: 0.42rem;
			
			p:not(:last-child){
				margin-bottom: 0.4rem;
			}
		}
	}
	.medinHk{
		&>div{
			padding: 0.32rem 0.26rem;
		}
		.medinHk-des{
			margin-top: 0.24rem;
			
			p{
				font-size: 0.28rem;
				line-height: 0.42rem;
			}
		}
	}
	.combo,
	.combo-yjqz{
		li{
			margin-bottom: 0.2rem;
		}
		p{
			margin-top: -0.12rem;
			margin-bottom: 0.19rem;
		}
	}
	.combo-yjqz{
		.new{
			padding: 0;
			margin-left: 0.12rem;
		}
	}
	.case{
		.box{
			display: flex;
			justify-content: space-around;
			margin-bottom: 0.16rem;
			
			.van-image:not(:last-child){
				margin-right: 0.17rem;
			}
		}
	}
	.experts{
		li{
			display: flex;
			justify-content: space-around;
			padding: 0.32rem 0;
			margin-bottom: 0.16rem;
		}
		ul{
			.van-image{
				box-shadow:0 0.03rem 0.06rem 0 rgb(203,208,217);
			}
		}
		dl{
			width: 4.6rem;
			
			dd{
				line-height: 0.36rem;
				margin-top: 0.1rem;
			}
		}
	}
	.paper-des{
		width: 6.45rem;
		margin-top: 0.18rem;
		padding-bottom: 0.6rem;
		line-height: 0.36rem;
	}
	.van-tabbar{
		height: 0.98rem;
		
		span{
			font-size: 0.2rem;
		}
		img{
			width: 0.43rem;
			height: 0.44rem;
		}
	}
	
	// 未完成提示
	.assess-nodone{
		width: 6.3rem;
		padding: 0.56rem;
		border-radius: 0.16rem;
		overflow-y: visible;
		
		p{
			line-height: 0.52rem;
			margin: 0.3rem 0;
		}
		.van-button{
			width: 2.5rem;
			line-height: 0.76rem;
			margin: 0 0.04rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.22rem;
			left: 50%;
			bottom: -1.72rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
		
		&.yjqz-alert{
			width: 6.5rem;
			height: 10.14rem;
			padding: 0;
			background-color: transparent;
			
			.yjqz-box{
				.van-button{
					position: absolute;
					left: 4%;
					bottom: 0.48rem;
					width: 91%;
					height: 0.74rem;
					border-radius: 0.08rem;
				}
			}
		}
	}
	
	.to-login{
		left: 0;
		bottom: 0.98rem;
		width: 100%;
		background-color: rgba(0,0,0,.7);
		padding: 0 0.32rem;
		line-height: 0.9rem;
		z-index: 999;
		
		.van-image{
			vertical-align: middle;
			margin-right: 0.1rem;
		}
		.van-button{
			width: 1.62rem;
			height: 0.56rem;
			padding: 0;
			border: none;
			border-radius: 0.08rem;
			margin-top: 0.17rem;
			line-height: 0.56rem;
		}
	}

	// 同心携手,共同战疫
	.givingMasks{
		width: 6.64rem;
		border-radius: 0.16rem;
		margin-top: -0.48rem;
		overflow: visible;
		
		div.qjc-bg-fff{
			padding: 0 0.41rem 0.12rem;
			border-radius: 0.16rem;
			
			ul{
				padding: 0.48rem 0 0.4rem;
				
				li{
					text-align: left;
					color: #797E96;
					padding-left: 0.32rem;
					background: url(../../assets/images/givingMasks_gou.png) no-repeat;
					background-position:  left 0.105rem;
					background-size: 0.2rem 0.15rem;
					line-height: 0.36rem;
					
					&:not(:last-child){
						margin-bottom: 0.28rem;
					}
				}
			}
			.click-buy{
				height: 0.73rem;
				line-height: 0.73rem;
				background: url(../../assets/images/givingMasks_btn_bg.png) no-repeat;
				background-size: 100% 100%;
			}
			p{
				font-size: 0.2rem;
				line-height: 0.48rem;
				color: #A1A7B2;
			}
		}
		.close-btn{
			bottom: -0.96rem;
			left: 50%;
			transform: translateX(-50%);
		}
		.click-scale{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	.givingMasks-s{
		right: 0.32rem;
		bottom: 1.3rem;
		
		.close{
			top: -0.12rem;
			right: 0;
		}
	}
	
	.yjqz{
		div.qjc-bg-fff{
			ul{
				li{
					font-size: 0.26rem;
					line-height: 0.4rem;
				}
			}
		}
	}
	.am-hide {
		display: none;
	}
</style>
